import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./MakeOfferScreen.css";
import { motion } from "framer-motion";
import pictures from "../assets/image2.png";


import AllCities from "../components/AllCities";
import AllCategories from "../components/AllCategories";
import TravauxEnHateur from "../components/subcatgories/TravauxEnHateur";
import LevageEtManutention from "../components/subcatgories/LevageEtManutention";
import TerrassementEtExcavation from "../components/subcatgories/TerrassementEtExcavation";
import TransportEtLogistique from "../components/subcatgories/TransportEtLogistique";
import GrosOeuvre from "../components/subcatgories/GrosOeuvre";
import EnergieEtPompage from "../components/subcatgories/EnergieEtPompage";
import EquipementDeChantier from "../components/subcatgories/EquipementDeChantier";
import BaseVie from "../components/subcatgories/BaseVie";
import FileSizeAlert from "../components/FileSizeAlert";
import Footer from "../components/Footer";


import { createOffer } from "../redux/actions/offerActions";
import { OFFER_CREATE_RESET } from "../redux/constants/offerConstants";
import axios from "axios";

const MakeOfferScreen = () => {
  
  const [offerName, setOfferName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [city, setCity] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("");

  
  const [uploading, setUploading] = useState(false);
  const [stagedFormData, setStagedFormData] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageCountError, setImageCountError] = useState("");
  const [showSizeAlert, setShowSizeAlert] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const offerCreate = useSelector((state) => state.offerCreate);
  const { success, error, loading } = offerCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleClick = async (e) => {
    e.preventDefault();
    if (imagePreviews.length === 0) {
      setImageCountError("Vous devez ajouter une image.");
      return;
    }

    if (!offerName || !category || !city || !type || !price || !desc) {
      setImageCountError("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        "/api/upload/",
        stagedFormData,
        config
      );

      if (data?.length > 0) {
        dispatch(
          createOffer({
            user: userInfo._id,
            title: offerName,
            city,
            category,
            subcategory: subCategory,
            images: data.map((image) => `${image}`),
            type,
            price,
            description: desc,
          })
        );
      }
    } catch (error) {
      console.error(error);
      setImageCountError("Une erreur s'est produite lors du téléchargement.");
    } finally {
      setUploading(false);
    }
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const newPreviews = [];
    const MAX_FILE_SIZE = 2 * 1024 * 1024; 
  
    
    if (files.length + imagePreviews.length > 3) {
      setImageCountError("Vous ne pouvez télécharger que 3 images au maximum.");
      return;
    }

    
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE) {
        setShowSizeAlert(true);
        return;
      }
    }
  
    setImageCountError(""); 
    const formdata = stagedFormData || new FormData();
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
  
      reader.onload = (event) => {
        newPreviews.push(event.target.result);
        if (newPreviews.length === files.length) {
          setImagePreviews([...imagePreviews, ...newPreviews]);
        }
      };
  
      formdata.append("images", file);
      reader.readAsDataURL(file);
    }
    setStagedFormData(formdata);
  };

  const deleteImagePreview = (index) => {
    if (index < 0 || index >= imagePreviews.length) return;
    
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(updatedPreviews);
    
    
    const newFormData = new FormData();
    const existingFiles = stagedFormData.getAll('images');
    existingFiles.forEach((file, i) => {
      if (i !== index) {
        newFormData.append('images', file);
      }
    });
    setStagedFormData(newFormData);
  
    
    if (updatedPreviews.length < 3) {
      setImageCountError(""); 
    }
  };

  const handleKeyPress = (e) => {
    if (['-', 'e', '.', '+', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (userInfo) {
      document.title = "Création d'annonce";

      if (success && !uploading) {
        navigate("/offersuccess");
        dispatch({
          type: OFFER_CREATE_RESET,
        });
      }
    } else {
      navigate("/");
    }
  }, [userInfo, navigate, success, uploading, dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="makenewoffer__wrapper"
    >
      <div className="makenewoffer">
        <h1>Ajouter une annonce</h1>
        <div className="image__upload">
          <div className="inside__upload">
            <label htmlFor="upload-input">
              <img src={pictures} draggable="false" alt="pictures" />
            </label>
            <input
              id="upload-input"
              type="file"
              onChange={handleImageChange}
              multiple
              accept="image/*"
            />
          </div>
          <div className="image_preview_container">
            {imagePreviews.length > 0 ? (
              imagePreviews.map((preview, index) => (
                <div className="image-preview" key={index}>
                  <img
                    src={preview}
                    alt={`Image ${index + 1}`}
                    className="image__preview"
                  />
                  <i
                    className="fa-solid fa-trash close-icon"
                    onClick={() => deleteImagePreview(index)}
                  ></i>
                </div>
              ))
            ) : (
              <div className="conseil">
                <div className="conseil__inside">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                  <h4>Conseils</h4>
                </div>
                <ul>
                  <li>Une annonce avec des images indicatifs est plus visible aux utilisateurs</li>
                  <li>Remplir tous les cases nécessaires</li>
                  <li>Les images ne doivent pas dépasser 10 Mo</li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="newoffer__details">
          <div className="newoffer__name">
            <label>Titre d'annonce</label>
            <input
              type="text"
              value={offerName}
              onChange={(e) => setOfferName(e.target.value)}
              required
            />
          </div>

          <div className="newoffer__name">
            <label>Type de d'annonce</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="">Choisissez le type d'annonce</option>
              <option value="rent">Location</option>
              <option value="buy">Vente</option>
            </select>
          </div>

          <div className="newoffer__name">
            <label>Catégorie</label>
            <AllCategories category={category} setCategory={setCategory} />
          </div>

          <div className="newoffer__name">
            {category && (
              <>
                <label>Sous-catégorie</label>
                {category === "Travaux en hauteur" && (
                  <TravauxEnHateur
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
                {category === "Levage et manutention" && (
                  <LevageEtManutention
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
                {category === "Terrassement et excavation" && (
                  <TerrassementEtExcavation
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
                {category === "Transport et logistique" && (
                  <TransportEtLogistique
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
                {category === "Gros oeuvre et route" && (
                  <GrosOeuvre
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
                {category === "énergie et pompage" && (
                  <EnergieEtPompage
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
                {category === "Equipement de chantier" && (
                  <EquipementDeChantier
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
                {category === "Base vie et installation de chantier" && (
                  <BaseVie
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                  />
                )}
              </>
            )}
          </div>

          <div className="newoffer__name">
            <label>Ville</label>
            <AllCities city={city} setCity={setCity} />
          </div>

          <div className="newoffer__name">
            <label>Prix</label>
            <input
              type="number"
              value={price}
              className="no_spinners"
              placeholder={type === "rent" ? "prix par jour" : "prix"}
              onChange={(e) => setPrice(e.target.value)}
              onKeyDown={handleKeyPress}
              required
            />
          </div>

          <div className="offer__name__step2">
            <label>Déscription</label>
            <textarea
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              required
            />
          </div>
        </div>

        {error && <h4 className="offer__error">{error}</h4>}
        {imageCountError && <h4 className="offer__error">{imageCountError}</h4>}
        
        <button
          type="button"
          onClick={handleClick}
          className="createoffer__btn"
          disabled={loading || uploading}
        >
          {loading || uploading ? "Création en cours..." : "Créer cette annonce"}
        </button>
      </div>

      <FileSizeAlert 
        isOpen={showSizeAlert} 
        onClose={() => setShowSizeAlert(false)} 
      />
      
      <Footer />
    </motion.div>
  );
};

export default MakeOfferScreen;