import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { createOffer } from "../redux/actions/offerActions";
import { OFFER_CREATE_RESET } from "../redux/constants/offerConstants";

import "./FourthStepScreen.css";
import OfferSteps from "../components/OfferSteps";
import pictures from "../assets/image2.png";
import axios from "axios";

const FourthStepScreen = () => {
  const offerCreate = useSelector((state) => state.offerCreate);
  const { success, error, loading, titleAndCategory, cityAndDescription } = offerCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [uploading, setUploading] = useState(false);
  const [stagedFormData, setStagedFormData] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageCountError, setImageCountError] = useState("");
  const [showSizeAlert, setShowSizeAlert] = useState(false);

  const maxFileSize = 2 * 1024 * 1024;

  const backToStepThree = () => {
    if (userInfo) {
      navigate("/makeoffer/step2");
    } else {
      navigate("/makeoffer/step3");
    }
  };

  const { offerName, type, category, subCategory } = JSON.parse(localStorage.getItem("titleAndCategory")) || {};
  

  const { city, price, description} = JSON.parse(localStorage.getItem("cityAndDescription"));

  const handleClick = async (e) => {
    e.preventDefault();
    if (imagePreviews.length === 0) {
      setImageCountError("Vous devez ajouter une image.");
      return;
    }

    if (!offerName || !category || !city || !price || !description) {
      setImageCountError("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        "/api/upload/",
        stagedFormData,
        config
      );

      if (data?.length > 0) {
        dispatch(
          createOffer({
            user: userInfo._id,
            title: offerName,
            city,
            category,
            subcategory: subCategory,
            images: data.map((image) => `${image}`),
            type,
            price,
            description,
          })
        );
      }
    } catch (error) {
      console.error(error);
      setImageCountError("Une erreur s'est produite lors du téléchargement.");
    } finally {
      setUploading(false);
    }
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const newPreviews = [];
    const MAX_FILE_SIZE = 2 * 1024 * 1024; 
  
    
    if (files.length + imagePreviews.length > 3) {
      setImageCountError("Vous ne pouvez télécharger que 3 images au maximum.");
      return;
    }

    
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE) {
        setShowSizeAlert(true);
        return;
      }
    }
  
    setImageCountError(""); 
    const formdata = stagedFormData || new FormData();
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
  
      reader.onload = (event) => {
        newPreviews.push(event.target.result);
        if (newPreviews.length === files.length) {
          setImagePreviews([...imagePreviews, ...newPreviews]);
        }
      };
  
      formdata.append("images", file);
      reader.readAsDataURL(file);
    }
    setStagedFormData(formdata);
  };

  const deleteImagePreview = (index) => {
    if (index < 0 || index >= imagePreviews.length) return;
    
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(updatedPreviews);
    
    
    const newFormData = new FormData();
    const existingFiles = stagedFormData.getAll('images');
    existingFiles.forEach((file, i) => {
      if (i !== index) {
        newFormData.append('images', file);
      }
    });
    setStagedFormData(newFormData);
  
    
    if (updatedPreviews.length < 3) {
      setImageCountError(""); 
    }
  };

  useEffect(() => {
    document.title = "Création d'annonce";
    if (success) {
      navigate("/offersuccess");
      dispatch({
        type: OFFER_CREATE_RESET,
      });
    }
  }, [navigate, success, dispatch]);

  return (
    <div className="offersummaryscreen">
      <div className="summary__inside">
        <OfferSteps step1 step2 step3 step4 />
        <h1>Vue d'ensemble de l'annonce</h1>

        <div className="offer__summaryBox">
            <>
              <div className="info__miniSection">
                <h2>Titre de l'offre</h2>
                <p>{offerName}</p>
              </div>
              <div className="info__miniSection">
                <h2>Type</h2>
                <p>{type === "rent" ? "Location" : "Vente"}</p>
              </div>
              <div className="info__miniSection">
                <h2>Prix</h2>
                <p>
                  {price}
                  {type === "rent" ? "DH / jour" : " DH"}
                </p>
              </div>
              <div className="info__miniSection">
                <h2>Catégorie</h2>
                <p>{category}</p>
              </div>
              <div className="info__miniSection">
                <h2>Sous-catégorie</h2>
                <p>{subCategory}</p>
              </div>
              <div className="info__miniSection">
                <h2>Ville</h2>
                <p>{city}</p>
              </div>
              <div className="info__miniSection">
                <h2>Description</h2>
                <p>{description}</p>
              </div>
            </>
          <div className="info__miniSection">
            <h2>Images</h2>
            <div className="offer__images">
              <div className="image__uploadSummary">
                <div className="inside_upload_summ">
                  <label htmlFor="upload-input">
                    <img src={pictures} draggable="false" alt="pictures" />
                  </label>
                  <input
                    id="upload-input"
                    type="file"
                    onChange={handleImageChange}
                    multiple
                    accept="image/*"
                  />
                </div>
                {imagePreviews.length > 0 ? (imagePreviews.map((img, index) => (
                  <div key={index} className="image-preview-container">
                    <div className="image-previewsummary">
                      <img
                        src={img}
                        alt={`Image ${ index + 1 }`}
                        className="image__previews"
                      />
                      <i
                        className="fa-solid fa-trash close-iconoffersumm"
                        onClick={() => deleteImagePreview(index)}
                      ></i>
                    </div>
                  </div>
                ))):<></>}
              </div>
            </div>
          </div>
        </div>
        {error && <h4 className="offer__error">{error}</h4>}
        {imageCountError && <h4 className="offer__error">{imageCountError}</h4>}
        <section className="offer__buttons">
          <button className="save__offer" onClick={backToStepThree}>
            Retour
          </button>
          <button className="save__offer" onClick={handleClick} disabled={uploading}>
            {uploading ? "Chargement..." : "Suivant"}
          </button>
        </section>
      </div>
    </div>
  );
};

export default FourthStepScreen;
